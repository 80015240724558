import { HttpErrorResponse } from '@angular/common/http'
import * as Sentry from '@sentry/angular'
import { DateTime } from 'luxon'
import { Observable, of } from 'rxjs'
import { Err, Result } from 'ts-results'
import { LessonStatus } from '../../models/lesson.model'
import { SelectMenuOption } from '../../models/select-menu-option.model'
import { TeamMemberStatus } from '../../models/team-member.model'
import { UserRole } from '../../models/user.model'
import { NoteMarkerType } from '../../note/models/note-marker-type.enum'
import { LessonSimpleStatus } from '../../report/models/lesson-simple-status.enum'
import { CsvUploadError, CsvUploadIssueCode } from '../components/csv-upload-modal/models/csv-upload-error.model'

export function isJsonString(str: string): boolean {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export function handleBasicHttpError<T>(
  error: HttpErrorResponse,
): Observable<Result<T, Error>> {
  Sentry.captureException(error)

  if (error.error instanceof ErrorEvent) {
    return of(Err(Error(error.error.message)))
  }

  if (error.error?.message) {
    return of(Err(Error(error.error.message)))
  }

  if (error.error instanceof ProgressEvent) {
    return of(Err(Error(error.message)))
  }

  if (isJsonString(error.error?.toString())) {
    const originalError = JSON.parse(error.error)
    return of(Err(originalError))
  }

  return of(Err(Error('Unknown error')))
}

export function handleCsvUploadError(
  error: HttpErrorResponse,
): Observable<Result<void, CsvUploadError[]>> {
  Sentry.captureException(error)

  return of(Err(error.error))
}

export function getFriendlyRole(role: string): string {
  if (role === UserRole.ACCOUNT_ADMIN) return 'Admin'

  const userRole = UserRole[role as keyof typeof UserRole]

  return titleCase(userRole || 'Unknown')
}

export function getFriendlyTeamMemberStatus(status: string): string {
  return TeamMemberStatus[status as keyof typeof TeamMemberStatus] || 'Unknown'
}

export function getFriendlyProgramLevel(programLevel?: string): string {
  return titleCase(programLevel || 'Unknown')
}

export function getUpcomingIEPDate(lastIEPDate?: Date): Date | undefined {
  if (!lastIEPDate) return
  return DateTime.fromJSDate(lastIEPDate).plus({ year: 1 }).minus({ day: 1 }).toJSDate()
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getNestedValue(path: string, obj: any): any {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj)
}

// This is intended to replace any falsy value with undefined
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getValueOrUndefined(value?: any): any | undefined {
  return value?.length > 0 ? value : undefined
}

export function getUserInitials(user: {
  firstName: string
  lastName: string
}): string {
  return `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`.toUpperCase()
}

export function getFriendlyLessonStatus(status?: LessonStatus): string {
  if (!status) return status || ''
  return titleCase(status)
}

export function getFriendlyLessonDataType(status: string): string {
  return titleCase(status || 'Unknown')
}

export function getFriendlyNoteMarkerType(noteMarkerType?: string): string {
  return titleCase(noteMarkerType || 'Unknown')
}

export const STATUS_CHANGE_NOTE_MARKER_TYPES = [
  NoteMarkerType.LESSON_MAINTENANCE,
  NoteMarkerType.LESSON_MASTERED,
  NoteMarkerType.LESSON_DISCONTINUED,
]

export const UNASSIGN_NOTE_MARKER_TYPES = [
  NoteMarkerType.LESSON_MASTERED,
  NoteMarkerType.LESSON_DISCONTINUED,
]

export function getFriendlyNoteMarkerTypeSelectOptions(
  exclude: NoteMarkerType[],
  addStatusChangeLabels = false,
): SelectMenuOption[] {
  return Object.values(NoteMarkerType)
    .map((value) => ({
      value,
      label:
        getFriendlyNoteMarkerType(value) +
        (addStatusChangeLabels &&
          STATUS_CHANGE_NOTE_MARKER_TYPES.includes(value)
          ? ' (Status Change)'
          : ''),
    }))
    .filter((option) => !exclude.includes(option.value))
}

export function getFrindlyLessonSimpleStatus(
  status: LessonSimpleStatus,
): string {
  return titleCase(status)
}

export function getFriendlyCsvUploadIssueCode(code: string): string {
  switch (code) {
    case CsvUploadIssueCode.INVALID_TYPE:
      return 'Invalid Type'
    case CsvUploadIssueCode.INVALID_LITERAL:
      return 'Invalid Literal'
    case CsvUploadIssueCode.CUSTOM:
      return 'Custom'
    case CsvUploadIssueCode.INVALID_UNION:
      return 'Invalid Union'
    case CsvUploadIssueCode.INVALID_UNION_DISCRIMINATOR:
      return 'Invalid Union Discriminator'
    case CsvUploadIssueCode.INVALID_ENUM_VALUE:
      return 'Invalid Value'
    case CsvUploadIssueCode.UNRECOGNIZED_KEYS:
      return 'Unrecognized Keys'
    case CsvUploadIssueCode.INVALID_ARGUMENTS:
      return 'Invalid Arguments'
    case CsvUploadIssueCode.INVALID_RETURN_TYPE:
      return 'Invalid Return Type'
    case CsvUploadIssueCode.INVALID_DATE:
      return 'Invalid Date'
    case CsvUploadIssueCode.INVALID_STRING:
      return 'Invalid String'
    case CsvUploadIssueCode.TOO_SMALL:
      return 'Too Small'
    case CsvUploadIssueCode.TOO_BIG:
      return 'Too Big'
    case CsvUploadIssueCode.INVALID_INTERSECTION_TYPES:
      return 'Invalid Intersection Types'
    case CsvUploadIssueCode.NOT_MULTIPLE_OF:
      return 'Not Multiple Of'
    case CsvUploadIssueCode.NOT_FINITE:
      return 'Not Finite'
    case CsvUploadIssueCode.WRONG_TEMPLATE:
      return 'Template Not Right'
    case CsvUploadIssueCode.DATABASE_ERROR:
      return 'Database Error'
    case CsvUploadIssueCode.DUPLICATE:
      return 'Duplicate'
    case CsvUploadIssueCode.MULTIPLE_FILES:
      return 'Multiple Files'
    case CsvUploadIssueCode.INVALID_FILE_EXTENSION:
      return 'Invalid File Extension'
    case CsvUploadIssueCode.STUDENT_COUNT_EXCEEDED:
      return 'Student Count Exceeded'
    case CsvUploadIssueCode.INVALID_USER_ROLE:
      return 'Invalid User Role'
    case CsvUploadIssueCode.SCHOOL_NOT_FOUND:
      return 'School Not Found'

    default:
      return code
  }
}

function titleCase(str: string): string {
  return str
    .toLowerCase()
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
    .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase())
}
